import Alpine from "alpinejs";
import { isUrlExternal } from "is-url-external";

Alpine.magic("openModal", () => (id) => {
  return modalEvent("open-modal", id);
});

Alpine.magic("closeModal", () => (id) => {
  return modalEvent("close-modal", id);
});

Alpine.magic("filterUrlsFromAccessList", () => (filterRule, accessList, id) => {
  document.body.addEventListener("click", function (e) {
    let targetElement = getTargetLink(e.target);
    if (targetElement) {
      let reference = targetElement.getAttribute("href");
      // return true if link is found in accessList based on the fitler rule set in the dialog & open modal
      if (filterLink(reference, accessList, filterRule)) {
        setProceedUrl(reference, id);
        //dont open modal or prevent if the link is on the current open modal
        if (!targetElement.classList.contains(`exitLink-${id}`)) {
          // prevent link clicked from proceeding
          e.preventDefault();
          //set proceed link on modal button type
          modalEvent("open-modal", id);
        }
      }
    }
  });
});

function setProceedUrl(href, id) {
  const proceedLink = document.querySelector(`.exitLink-${id}`);
  if (proceedLink) proceedLink.setAttribute("href", href);
}

function modalEvent(eventType, id) {
  const event = new CustomEvent(eventType, { detail: { id } });
  return window.dispatchEvent(event);
}

function filterLink(href, accessList, filterRule) {
  let isRegisteredLink = false;
  switch (filterRule) {
    case "whitelist":
      isRegisteredLink = !accessList.find((url) => url === href);
      break;
    case "blacklist":
      isRegisteredLink = accessList.find(url => url === href);
      break;
    default:
      let externalURL = isUrlExternal(href, window.location.hostname.replace(/^www\./, ""));
      if (externalURL) {
        isRegisteredLink = true;
      }
  }
  return isRegisteredLink;
}

const getTargetLink = function (target) {
  let el = null;
  if (target.localName == "a" && target.dataset.skipModal !== "true") {
    el = target;
  } else {
    let targetEl = target.closest("a");
    if (targetEl && targetEl.dataset.skipModal !== "true") {
      // when anchor link has html tag inside
      el = targetEl;
    }
  }
  return el;
};
